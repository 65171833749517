'use client'

import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { initReactI18next, useTranslation as useTranslationOrg, UseTranslationResponse } from 'react-i18next'
import { useCookies } from 'react-cookie'
import resourcesToBackend from 'i18next-resources-to-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { getOptions, languages, cookieName, fallbackLng } from './settings'
import { usePathname } from "next/navigation";
import { isOnServerSide } from "@/lib/infrastructure/serverclient";

export function GetPathLanguage(): string | null {
  const path: string = usePathname();
  const parts: string[] = path.split("/", 2);
  if (parts.length == 0) {
    return null;
  }
  const lang: string = parts[1];

  for (const language of languages) {
    if (language === lang) {
      return lang;
    }
  }
  return null;
}

//
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(resourcesToBackend((language: string, namespace: string) => import(`/public/locales/${language}/${namespace}.json`)))
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on the client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
    preload: isOnServerSide ? languages : []
  })

export function useTranslation(lng?: string, ns1?: string, ns2?: string): UseTranslationResponse<string, string> {
  const namespace : string[] = [ns1 || "", ns2 ||"", "common"].filter((ns) => ns.length > 0);
  let language: string = lng ?? fallbackLng;

  const [cookies, setCookie] = useCookies([cookieName]);
  const ret = useTranslationOrg(namespace) as UseTranslationResponse<string, string>;
  const { i18n } = ret;
  if (isOnServerSide && language && i18n.resolvedLanguage !== language) {
    i18n.changeLanguage(language);
  } else {
    language = GetPathLanguage() || language;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (activeLng === i18n.resolvedLanguage) {
        return;
      }
      setActiveLng(i18n.resolvedLanguage);
    }, [activeLng, i18n.resolvedLanguage]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!language || i18n.resolvedLanguage === language) {
        return;
      }
      i18n.changeLanguage(language);
    }, [language, i18n]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (cookies.i18next === language) {
        return;
      }
      setCookie(cookieName, language, { path: '/', sameSite: 'strict' });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, cookies.i18next]);
  }
  return ret;
}